import {
  FONTS_STYLE_TAG_ID,
  POWR_SSR_DATA_ID,
  POWR_SSR_ID,
  WC_CLASS_NAME_LT_428,
  WC_MOBILE_WIDTH,
  POWR_MARK_GAP,
  RENDER_WEBCOMPONENT_IN_RAILS_VIEW_PARAM,
} from './constants';
import { isStandalone, isWixEditor } from './hydrateHelpers';

export function handleResponsiveStyles(shadowRoot) {
  const styleResponsive = () => {
    const __powr_ssr = shadowRoot.querySelector(`#${POWR_SSR_ID}`);
    // workaround until https://caniuse.com/css-container-queries is supported
    if (isStandalone(shadowRoot)) {
      if (this.clientWidth < WC_MOBILE_WIDTH) {
        __powr_ssr.classList.add(WC_CLASS_NAME_LT_428);
      } else {
        __powr_ssr.classList.remove(WC_CLASS_NAME_LT_428);
      }
    }
  };
  styleResponsive();
  setInterval(styleResponsive, 500);
}

export function addHydrationScript(src) {
  const script = document.createElement('script');
  script.setAttribute('src', src);
  this.appendChild(script);
}

export function addFonts(shadowRoot) {
  // load fonts outside of webcomponent to ensure fonts work correctly https://dev.to/akdevcraft/use-font-in-web-component-51a4
  const fontsStyleTag = shadowRoot.querySelector(`#${FONTS_STYLE_TAG_ID}`);
  document.head.appendChild(fontsStyleTag.cloneNode(true));
}

export function addShadowRootToWindow(shadowRoot) {
  window.shadowRoots ||= [];
  window.shadowRoots.push(shadowRoot);
}

export function applyIframeStyles(iFrame) {
  const styles = {
    border: 'none',
    display: 'block',
    visibility: 'hidden', //Prevents blinking
  };
  for (const i in styles) {
    iFrame.style[i] = styles[i];
  }
}

export function removeMarginTopInsideIframe() {
  if (pageInsideIframe()) {
    document.querySelector('web-component-page').style.marginTop = 0;
  }
}

export function pageInsideIframe() {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

export function isWix(meta) {
  return meta?.external_id_type === 'wix';
}

function isServer() {
  return typeof window === 'undefined';
}

export function isRailsViewPage() {
  if (!isServer()) {
    const searchParams = new URLSearchParams(document.location.search);
    return searchParams.has(RENDER_WEBCOMPONENT_IN_RAILS_VIEW_PARAM);
  }
}

export function sendResizeMessage(shadowRoot) {
  if (!pageInsideIframe() || isStandalone(shadowRoot) || isWixEditor(shadowRoot)) return;
  const ssrData = getSsrData(shadowRoot);
  // send hashid to change height for certain app
  const hashid = ssrData.props.meta.hashid;
  const uniqueLabel = ssrData.props.meta.unique_label;
  const shadowRootHeight = shadowRoot.getElementById(POWR_SSR_ID).offsetHeight;
  if (shadowRootHeight > 0) {
    const height = shadowRootHeight + POWR_MARK_GAP;
    window.parent.postMessage(
      JSON.stringify({
        src: window.location.toString(),
        context: 'iframe.resize',
        // get height of app content and pass to iframe
        height,
        hashid,
        uniqueLabel,
      }),
      '*'
    );
  }
}

export function getSsrData(shadowRoot) {
  return JSON.parse(shadowRoot.querySelector(`#${POWR_SSR_DATA_ID}`).textContent);
}

export function kebabize(camel) {
  return camel
    .split('')
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter;
    })
    .join('');
}

export function showIframe(iframe) {
  iframe.style.visibility = 'visible';
}
