export const CONTAINER_WIDTH_COEF = 1.02;
export const CONTAINER_HEIGHT_COEF = 1.38;

export const MOBILE_MAX_FONT_SIZE = '30px';

export const POWR_MARK_GAP = 4;

export const WC_CLASS_NAME_LT_428 = 'wc-lt-428';

export const WC_MOBILE_WIDTH = 428;

export const WC_TABLET_WIDTH = 768;

export const POWR_SSR_DATA_ID = '__POWR_SSR_DATA__';

export const POWR_SSR_ID = '__powr_ssr';

export const FONTS_STYLE_TAG_ID = '__fonts_css';

export const BOTTOM_WATERMARK_EXCEPTIONS = ['countdown-timer', 'appointments', 'nudge'];

export const APPS_WITHOUT_IFRAME = ['faq', 'countdown-timer', 'nudge'];

export const RENDER_WEBCOMPONENT_IN_RAILS_VIEW_PARAM = 'render_web_component';

export const LAST_VIEWED_URLS_TO_EXCLUDE = ['localhost', 'editor.wix.com', 'powr.io', 'powr-staging.io']
;
