import {
  addFonts,
  addHydrationScript,
  addShadowRootToWindow,
  handleResponsiveStyles,
  applyIframeStyles,
  getSsrData,
  showIframe,
} from './helpers/webcomponentHelpers';

class WebComponentEmbed extends HTMLElement {
  async connectedCallback() {
    const uniqueLabel = this.getUniqueLabel();
    const appSlug = this.getSlug();
    const isInPlatformsEditor = window.Shopify?.designMode || window.location.href.includes('cms.e.jimdo.com'); // component loaded in shopify/jimdo editor
    // https://shopify.dev/docs/themes/tools/online-editor#using-javascript
    if (isInPlatformsEditor && uniqueLabel && appSlug !== 'appointments') {
      let { appendRailsIframe } = await import('./modules/appendRailsIframe');
      this.appendRailsIframe = appendRailsIframe.bind(this);
      this.appendRailsIframe();
      return;
    }

    if (this.appRequiresIframe(appSlug)) {
      this.appendIframe();
      return;
    }
    const response = await fetch(this.getShadowUrl());
    if (response.status === 404) return;
    const shadowRoot = this.attachShadow({ mode: 'closed' });
    shadowRoot.innerHTML = await response.text();
    this.addShadowRootToWindow(shadowRoot);
    this.handleResponsiveStyles(shadowRoot);
    const ssrData = this.getSsrData(shadowRoot);
    // explicitly inserting hydration script, because above "innerHTML" method not allows scripts https://www.tutorialspoint.com/can-scripts-be-inserted-with-innerhtml
    this.addHydrationScript(`${ssrData.props.mainJsScriptPath}`);
    this.addFonts(shadowRoot);
  }
  appRequiresIframe(appSlug) {
    return ['map', 'appointments'].includes(appSlug);
  }
  appendIframe() {
    const iFrame = document.createElement('iframe');
    const appId = this.getAppId();
    const uniqueLabel = this.getUniqueLabel();
    const clientOrigin = document.location.origin;
    if (appId) {
      iFrame.src = `${NEXT_APP_URL}/${appId}?clientOrigin=${clientOrigin}`;
    } else if (uniqueLabel) {
      iFrame.src = `${NEXT_APP_URL}/api/ssr/shadow?unique_label=${uniqueLabel}&display=page&clientOrigin=${clientOrigin}`;
    }
    if (isFramer()) {
      this.style.width = '100%';
    }
    iFrame.width = '100%';
    iFrame.id = 'Iframe';
    this.applyIframeStyles(iFrame);
    this.appendChild(iFrame);
    window.addEventListener('message', event => {
      try {
        const data = JSON.parse(event.data);
        if (data.context === 'iframe.resize') {
          this.resizeIframe(iFrame, data, appId, uniqueLabel);
          this.showIframe(iFrame);
        }
      } catch (err) {
        console.log(err);
      }
    });
  }
  resizeIframe(iFrame, data, appId, uniqueLabel) {
    const resizeThisIframe = data.hashid === appId || data.uniqueLabel === uniqueLabel;
    if (resizeThisIframe) {
      iFrame.style.height = `${data.height}px`;
    }
  }
  getShadowUrl() {
    const appId = this.getAppId();
    if (appId) {
      return `${NEXT_APP_URL}/api/ssr/shadow?id=${appId}&display=embed`;
    }
    const uniqueLabel = this.getUniqueLabel();
    return `${NEXT_APP_URL}/api/ssr/shadow?unique_label=${uniqueLabel}&display=embed`;
  }
  getAppId() {
    return this.getAttribute('id');
  }
  getUniqueLabel() {
    return this.getAttribute('unique_label');
  }
  getSlug() {
    return this.getAttribute('app_slug');
  }
  handleResponsiveStyles = handleResponsiveStyles;
  addHydrationScript = addHydrationScript;
  addFonts = addFonts;
  addShadowRootToWindow = addShadowRootToWindow;
  applyIframeStyles = applyIframeStyles;
  getSsrData = getSsrData;
  showIframe = showIframe;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/requestIdleCallback
const defer = window.requestIdleCallback || window.requestAnimationFrame;
defer(() => {
  if (!window.customElements.get('web-component-embed')) {
    window.customElements.define('web-component-embed', WebComponentEmbed);
  }
})


function isFramer() {
  return [...document.scripts]
    .filter(script => script.src)
    .map(script => script.src)
    .some(
      item =>
        item.includes('https://events.framer.com/script') ||
        item.includes('https://framerusercontent.com/') ||
        item.includes('framercanvas.com')
    );
}

// NOTE: only for Jimdo and other platforms that don't support custom HTML tags in embed code, e.g. <web-component-embed>.
// Because Jimdo removes <web-component-embed> tags from embed code, following workaround
// uses regular <div> tags instead, and then dynamically inserts <web-component-embed> tag in it
(function insertWebComponentEmbedInDivs() {
  const powrDivs = [...document.querySelectorAll('.powrssr')];
  for (const powrDiv of powrDivs) {
    if (!powrDiv.getAttribute('loading')) {
      powrDiv.setAttribute('loading', 1);
      const webComponentEmbed = document.createElement('web-component-embed');
      if (powrDiv.getAttribute('id')) {
        webComponentEmbed.setAttribute('id', powrDiv.getAttribute('id'));
      } else if (powrDiv.getAttribute('unique_label')) {
        webComponentEmbed.setAttribute('unique_label', powrDiv.getAttribute('unique_label'));
      }
      webComponentEmbed.setAttribute('app_slug', powrDiv.getAttribute('app_slug'));
      powrDiv.appendChild(webComponentEmbed);
    }
  }
})();
