import { createRoot } from 'react-dom/client';
import Plugin from '../components/Plugin';
import { POWR_SSR_DATA_ID, POWR_SSR_ID } from './constants';

export const isEmbedded = shadowRoot => {
  return shadowRoot?.host?.tagName === 'WEB-COMPONENT-EMBED';
};

export const isStandalone = shadowRoot => {
  return shadowRoot.host?.tagName === 'WEB-COMPONENT-STANDALONE';
};

export const isWixEditor = shadowRoot => {
  return shadowRoot.host?.tagName === 'WEB-COMPONENT-WIX-EDITOR';
};

export const isRailsViewPage = shadowRoot => {
  return shadowRoot.host?.tagName === 'WEB-COMPONENT-RAILS-VIEW-PAGE';
};

const isPage = shadowRoot => {
  return shadowRoot.host?.tagName === 'WEB-COMPONENT-PAGE';
};

const setPowrWindowGlobals = ({meta, attributes, globals}) => {
  window.META = meta;
  window.APP_MODEL = {attributes};
  window.GLOBALS = globals;
};

const drawSsrContainer = shadowRoot => {
  const ssrId = shadowRoot.querySelector(`#${POWR_SSR_ID}`);
  ssrId.remove();
  const div = document.createElement('div');
  div.setAttribute('id', POWR_SSR_ID);
  shadowRoot.appendChild(div);
};

// this function is called only from client side
const renderSsrApp = ({ Component, shadowRoot, renderParams } = {}) => {
  window.updatedDataFromWix = null;
  let props;

  window.webcomponentRender = () => {
    drawSsrContainer(shadowRoot);

    const root = createRoot(shadowRoot.querySelector(`#${POWR_SSR_ID}`));

    if (isStandalone(shadowRoot) || isWixEditor(shadowRoot) || isRailsViewPage(shadowRoot)) {
      props = window.webcomponentProps?.props;
    } else if (isEmbedded(shadowRoot) || isPage(shadowRoot)) {
      const ssrData = JSON.parse(shadowRoot.querySelector(`#${POWR_SSR_DATA_ID}`).textContent);
      props = ssrData.props;
      setPowrWindowGlobals(props); // allow to use window.META & window.APP_MODEL.attributes in browser console
      if(window.updatedDataFromWix) props.attributes = window.updatedDataFromWix;

      if(renderParams.initAppointmentsOnWix?.(props) == true) {
        props.attributes.timezone = renderParams.getTimezones?.();
        renderParams.createAvailabilityOnWix?.(props, root, Component, shadowRoot)
        return
      }
    }

    last_viewed_url(props);
    
    if (props) {
      root.render(
        <Plugin
          attributes={props.attributes}
          meta={props.meta}
          params={props.params}
          globals={props.globals}
          Component={Component}
          shadowRoot={shadowRoot}
        ></Plugin>
      );
    }
  };

  window.webcomponentRender();
  
  if (renderParams.isWixAppointment?.(props)) {
    renderParams.addWixListeners?.(window.webcomponentRender, props)
  }
}

export const renderSsrApps = (Component, renderParams = {}) => {
  for (const shadowRoot of window.shadowRoots) {
    renderSsrApp({ Component, shadowRoot, renderParams });
  }
};

async function last_viewed_url(props) {
  const searchParams = new URLSearchParams(document.location.search);
  if (searchParams.has('clientOrigin')) {
    const url = `${props.params.powrUrl}/api/v1/last-viewed-url/${props.meta.app_slug}/${props.meta.id}?url=${searchParams.get('clientOrigin')}`;
    await fetch(url, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-Type': 'application/json;charset=UTF-8',
      },
    })
  }
};
