import { WC_TABLET_WIDTH } from './constants';
export var isSsr = function () { return typeof window === 'undefined'; };
export var isPlatform = function () {
    if (!isSsr()) {
        return (window === null || window === void 0 ? void 0 : window.PLATFORM_EMBED) === 'platform';
    }
    return false;
};
export var isMobile = function () {
    if (!isSsr()) {
        return (window.innerWidth < WC_TABLET_WIDTH ||
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent));
    }
    return false;
};
export var debounce = function (fn, ms) {
    var timeoutId;
    return function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function () { return fn.apply(_this, args); }, ms);
    };
};
export var getURLParameter = function (str) {
    if (isSsr())
        return;
    return (window.decodeURIComponent((new RegExp('[?|&]' + str + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [, ''])[1].replace(/\+/g, '%20')) || null);
};
